/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'old_heating_energy_consumption__more_than_45000': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M136.98 69.69c0 37.31-30.25 67.56-67.56 67.56S1.86 107 1.86 69.69 32.11 2.13 69.42 2.13s67.56 30.25 67.56 67.56zM99.34 56.44l9.97-5.76M86.7 43.79l5.76-9.97M45.65 33.92l5.76 9.97M69.43 39.16V27.65M39.5 56.42l-9.97-5.75M34.86 73.7H23.35" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M75.81 62.15c-1.71-.39-3.48-.4-5.19-.02-1.71.38-3.32 1.12-4.7 2.19l-2.39 2.06c-.56.44-1.01 1-1.31 1.64-.3.64-.45 1.34-.44 2.05.02.71.2 1.4.53 2.03.33.62.8 1.16 1.38 1.58l2.27 1.77c3 2.08 6.61 3.08 10.25 2.82l33.02-4.1c3.14-.39 3.21-1.41.18-2.31l-33.6-9.69v-.02zM131.73 95.85H7.11" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
