<template>
    <div>
        <div class="question" id="question">
            <transition name="fade" mode="out-in">
                <div :key="step.backend_key" class="question__text">
                    {{ step.label }}
                    <span @click="showHelp = !showHelp"
                          v-if="step.help"
                          class="question__help-icon">
                        <HelpSvg v-if="!showHelp"/>
                        <CloseSvg v-if="showHelp"/>
                    </span>
                </div>
            </transition>
        </div>
        <help-text :show="showHelp" :text="step.help" @close="showHelp = !showHelp"/>
    </div>
</template>

<script>
import HelpText from '@/components/HelpText.vue';
import HelpSvg from '../../public/svg/help.svg';
import CloseSvg from '../../public/svg/close-question.svg';

export default {
    data() {
        return {
            step: '',
            showHelp: false,
        };
    },
    created() {
        this.init();
    },
    components: {
        HelpSvg,
        HelpText,
        CloseSvg,
    },
    methods: {
        init() {
            this.step = this.$root.appConfig.steps[this.$route.params.id];
            this.showHelp = false;
        },
    },
    watch: {
        $route: {
            handler() {
                this.init();
            },
            deep: true,
        },
    },
};
</script>
