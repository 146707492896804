/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__401_or_more': {
    width: 133,
    height: 133,
    viewBox: '0 0 133 133',
    data: '<path pid="0" d="M131.11 13.06v6" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M131.11 30.92v88.98s0 11.25-12.12 11.25H13.91s-12.12 0-12.12-11.25V13.06s0-11.25 12.12-11.25h105.08s3.24 0 6.37 1.56" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="11.85 11.85"/><path pid="2" d="M129.69 7.3c.87 1.48 1.42 3.37 1.42 5.76" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
