/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pipeline_system_exists__yes': {
    width: 139,
    height: 127,
    viewBox: '0 0 139 127',
    data: '<g clip-path="url(#clip0_3175_659)" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M4.44 89.62h11.75s2.94 0 2.94 2.94v29.38s0 2.94-2.94 2.94H4.44s-2.94 0-2.94-2.94V92.56s0-2.94 2.94-2.94zM121.94 1.5h11.75s2.94 0 2.94 2.94v29.38s0 2.94-2.94 2.94h-11.75s-2.94 0-2.94-2.94V4.44s0-2.94 2.94-2.94z"/><path pid="1" d="M42.62 95.5h-23.5V119h23.5c19.46-.02 35.23-15.79 35.25-35.25V72h-23.5v11.75c0 6.49-5.26 11.75-11.75 11.75zM89.62 7.38c-19.46.02-35.23 15.79-35.25 35.25v11.75h23.5V42.63c0-6.49 5.26-11.75 11.75-11.75H119V7.38H89.62z"/><path pid="2" d="M51.44 54.38h29.38s2.94 0 2.94 2.94v11.75s0 2.94-2.94 2.94H51.44s-2.94 0-2.94-2.94V57.32s0-2.94 2.94-2.94z"/></g><defs><clipPath id="clip0_3175_659"><path pid="3" _fill="#fff" d="M0 0h138.12v126.38H0z"/></clipPath></defs>'
  }
})
