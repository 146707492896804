/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__301_400': {
    width: 133,
    height: 133,
    viewBox: '0 0 133 133',
    data: '<path pid="0" d="M1.92 13.06s0-11.25 12.12-11.25h105.08s12.12 0 12.12 11.25M131.25 13.06V119.9s0 11.25-12.12 11.25M111.39 31.2v6" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M111.39 48.9v72.73s0 9.52-10.26 9.52H12.19s-10.26 0-10.26-9.52V31.2s0-9.52 10.26-9.52h88.94s1.73 0 3.76.66" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="11.7 11.7"/><path pid="2" d="M109.68 25.55c1.02 1.35 1.71 3.18 1.71 5.65" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
