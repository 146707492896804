/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_year_built__range_1961_1977': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M8.19 13.71h123.38s5.88 0 5.88 5.88v111.62s0 5.88-5.88 5.88H8.19s-5.88 0-5.88-5.88V19.59s0-5.88 5.88-5.88zM31.69 1.96v29.37M108.07 1.96v29.37M2.32 43.08h135.12" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M50.83 102.88V76.73v2.18c0 2.41-1.95 4.36-4.36 4.36h-2.18M57.37 102.88H44.29M22.66 96.94v-1.09c0-3.61 2.93-6.54 6.54-6.54s6.54 2.93 6.54 6.54v1.09c0 3.61-2.93 6.54-6.54 6.54s-6.54-2.93-6.54-6.54zm0 0V83.86c0-3.61 2.93-6.54 6.54-6.54 2.85 0 5.64 1.82 6.54 4.36M66.46 88.57h12.72M83.91 76.21h13.33l-.33.56a44.49 44.49 0 00-6.33 22.87v3.25M103.08 76.21h13.33l-.33.56a44.49 44.49 0 00-6.33 22.87v3.25" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
