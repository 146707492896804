/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'old_heating_energy_consumption__5000_20000': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M1.88 69.57c0 37.31 30.25 67.56 67.56 67.56S137 106.88 137 69.57 106.75 2.01 69.44 2.01 1.88 32.26 1.88 69.57zM29.55 50.56l9.97 5.76M46.41 33.7l5.76 9.97M87.45 43.77l5.76-9.97M69.44 27.53v11.51M109.34 50.55l-9.97 5.75M115.51 73.58H104" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M29.46 71.72c-3.03.9-2.96 1.92.18 2.31l33.02 4.1c3.64.25 7.26-.74 10.25-2.82l2.27-1.77c.58-.41 1.05-.95 1.38-1.58.33-.63.51-1.32.53-2.03.02-.71-.13-1.41-.44-2.05-.3-.64-.75-1.2-1.31-1.64l-2.39-2.06a11.925 11.925 0 00-4.7-2.19c-1.71-.38-3.48-.37-5.19.02l-33.6 9.69v.02zM7.13 95.72h124.63" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
