/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people_count__1_2': {
    width: 88,
    height: 105,
    viewBox: '0 0 88 105',
    data: '<path pid="0" d="M32.67 103.44l2.21-26.46h11.03V61.54c0-12.18-9.87-22.05-22.05-22.05-12.18 0-22.05 9.87-22.05 22.05v15.44h11.03l2.21 26.46h17.64-.02zM53.21 85.29l1.51 18.15h17.64l2.21-26.46H85.6V61.54c0-12.18-9.87-22.05-22.05-22.05-4.77 0-9.41 1.55-13.23 4.41M8.41 17.44c0 8.53 6.91 15.44 15.44 15.44s15.44-6.91 15.44-15.44S32.38 2 23.85 2 8.41 8.91 8.41 17.44zM48.11 17.44c0 8.53 6.91 15.44 15.44 15.44s15.44-6.91 15.44-15.44S72.08 2 63.55 2 48.11 8.91 48.11 17.44z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
