/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__0_100': {
    width: 134,
    height: 133,
    viewBox: '0 0 134 133',
    data: '<path pid="0" d="M2.28 80.69V13.06s0-11.25 12.12-11.25h105.08s12.12 0 12.12 11.25M131.61 13.06V119.9s0 11.25-12.12 11.25H52.64M42.96 94v6" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M42.96 111.13v16.48s0 3.54-3.81 3.54H6.1s-3.81 0-3.81-3.54v-33.6s0-3.54 3.81-3.54h27.32" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="11.12 11.12"/><path pid="2" d="M38.98 90.47h.17s3.81 0 3.81 3.54" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
