/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pv_system_existing_or_planned__interested': {
    width: 137,
    height: 110,
    viewBox: '0 0 137 110',
    data: '<path pid="0" d="M54.67 2.14v5.88M13.54 13.9l5.88 5.87M95.8 13.89l-5.88 5.88M1.8 55.02h5.87M81.03 31.65C68.1 17.09 45.82 15.78 31.27 28.7a35.236 35.236 0 00-11.84 26.31c0 3.92.66 7.81 1.96 11.51M42.08 56.53c.52-.93 1.5-1.51 2.57-1.51h61.17c1.07 0 2.05.58 2.57 1.51l26.11 47c.79 1.42.28 3.21-1.14 3.99-.44.24-.93.37-1.43.37H18.54c-1.62 0-2.94-1.32-2.94-2.94 0-.5.13-.99.37-1.43l26.11-47v.01zM63.48 55.02L51.73 107.9M86.98 55.02l11.75 52.88M33.13 72.64h84.21M23.34 90.27h103.79" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
