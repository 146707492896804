/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_year_built__range_1995_2001': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M7.77 13.71h123.38s5.88 0 5.88 5.88v111.62s0 5.88-5.88 5.88H7.77s-5.88 0-5.88-5.88V19.59s0-5.88 5.88-5.88zM31.27 1.96v29.37M107.64 1.96v29.37M1.89 43.08h135.12M64.73 88.57h12.72" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M33.79 83.26v1.09c0 3.61-2.93 6.54-6.54 6.54s-6.54-2.93-6.54-6.54v-1.09c0-3.61 2.93-6.54 6.54-6.54s6.54 2.93 6.54 6.54zm0 0v13.08c0 3.61-2.93 6.54-6.54 6.54-2.85 0-5.64-1.82-6.54-4.36M89.14 82.76v13.36c0 3.49 2.89 6.32 6.46 6.32h.37c3.57 0 6.46-2.83 6.46-6.32V82.76c0-3.49-2.89-6.32-6.46-6.32h-.37c-3.57 0-6.46 2.83-6.46 6.32zM117.22 101.88V75.73v2.18c0 2.41-1.95 4.36-4.36 4.36h-2.18M123.76 101.88h-13.08M53.74 76.73H42.76l-1.1 12.08 2.54-1.27c1.22-.61 2.57-.93 3.93-.93h.12a6.59 6.59 0 016.59 6.59v3.29a6.59 6.59 0 01-6.59 6.59c-2.87 0-5.68-1.83-6.59-4.39" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
