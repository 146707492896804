/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'years_since_old_heating_was_mounted__age_of_old_heating_unknown': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M66.15 101.94c0 19.47 15.78 35.25 35.25 35.25s35.25-15.78 35.25-35.25-15.78-35.25-35.25-35.25-35.25 15.78-35.25 35.25z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M54.4 107.81h-47c-3.24 0-5.88-2.63-5.88-5.88V19.68c0-3.24 2.63-5.88 5.88-5.88h105.75c3.24 0 5.88 2.63 5.88 5.88v38.19M30.9 2.07v29.37M89.65 2.07v29.37M1.53 43.19h117.5M101.4 103.42V85.8" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M101.4 118.1c-.81 0-1.47-.66-1.47-1.47 0-.81.66-1.47 1.47-1.47M101.4 118.1c.81 0 1.47-.66 1.47-1.47 0-.81-.66-1.47-1.47-1.47" _stroke="#000" stroke-width="3"/>'
  }
})
