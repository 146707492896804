/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__151_200': {
    width: 134,
    height: 133,
    viewBox: '0 0 134 133',
    data: '<path pid="0" d="M2.2 39.91V13.06s0-11.25 12.12-11.25H119.4s12.12 0 12.12 11.25M131.53 13.06V119.9s0 11.25-12.12 11.25h-28M80.61 59.56v6" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M80.61 77.15v47.18s0 6.82-7.35 6.82H9.55s-7.35 0-7.35-6.82V59.56s0-6.82 7.35-6.82H72.7" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="11.6 11.6"/><path pid="2" d="M78.17 54.32c1.34.99 2.43 2.61 2.43 5.24" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
