<template>
    <div>
        <form @submit.prevent="validate(validateZipcode)" class="zipcode-field__wrapper">
            <div class="zipcode-field">
                <div class="zipcode-field__image">
                    <img src="./../assets/zipcode.webp">
                </div>
                <div>
                    <div class="text-input">
                        <div class="text-input--icon">
                            <input type="text"
                                   name="zipcode"
                                   pattern="[0-9]{5}"
                                   maxlength="5"
                                   placeholder=""
                                   v-validate="{ required: true, regex: /^([0-9]{5})$/ }"
                                   v-model.trim="zipcode"
                                   :disabled="loading"
                                   id="zipcode"
                                   inputmode="numeric"
                                   autocomplete="off"
                            >
                            <label for="zipcode">Postleitzahl</label>
                            <search-svg class="text-input__icon"/>
                        </div>
                        <div
                            class="text-input__supporting-text"
                            :class="{ 'text-input__supporting-text--error':
                                     errors.has('zipcode') }"
                        >
                            {{ validationList.custom.zipcode.required }}
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <ErrorInfoBox v-if="showErrorInfoBox" class="zipcode-field"/>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapState } from 'vuex';
import validationList from '../config/validation.json';
import navCommonMixin from '@/mixins/mixin.navCommon';
import ErrorInfoBox from '@/components/ErrorInfoBox.vue';
import SearchSvg from '../../public/svg/search.svg';

export default {
    inject: ['$validator'],
    mixins: [navCommonMixin],
    components: {
        ErrorInfoBox,
        SearchSvg,
    },
    data() {
        return {
            step: this.$root.appConfig.steps[this.$route.params.id],
            validationList,
        };
    },
    computed: {
        ...mapState('ui', [
            'loading',
            'showErrorInfoBox',
        ]),
        ...mapFields('collectedData/customer', [
            'zipcode',
        ]),
    },
};
</script>
