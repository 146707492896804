/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__middle_terraced_house': {
    width: 141,
    height: 59,
    viewBox: '0 0 141 59',
    data: '<path pid="0" d="M89.17 18.8V6.5c0-.69-.56-1.26-1.26-1.26h-5.02c-.69 0-1.26.56-1.26 1.26v5.37L71.18 2.26a1.26 1.26 0 00-1.7 0L41.87 28.17a1.255 1.255 0 00.85 2.18H49V54.2c0 1.39 1.12 2.51 2.51 2.51h37.66c1.39 0 2.51-1.12 2.51-2.51V30.35h6.28a1.254 1.254 0 00.85-2.18l-9.64-9.37zM16.27 56.7h9.03M33.39 56.7h9.03M48.93 50.77v-9.03M9.88 50.77v-9.03M43.82 14.7l5.11 5.11M29.4 1.92l6.39 6.39M9.09 21.08l6.39-6.38M23.01 8.31l6.39-6.39M3.8 27.33l-1.84 1.98a1.255 1.255 0 00.85 2.18h6.28v1.99M124.43 56.7h-9.03M107.32 56.7h-9.04M130.82 50.77v-9.03M96.88 14.7l-5.11 5.11M111.3 1.92l-6.39 6.39M131.61 21.08l-6.39-6.38M117.69 8.31l-6.39-6.39M131.61 33.48v-1.99h6.28c.13 0 .26-.02.38-.06.92-.29 1.19-1.47.47-2.12l-1.84-1.98" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
