/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'old_heating_energy_consumption__21000_25000': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M1.5 69.68c0 37.31 30.25 67.56 67.56 67.56s67.56-30.25 67.56-67.56S106.37 2.12 69.06 2.12 1.5 32.37 1.5 69.68zM46.03 33.81l5.76 9.97M87.07 43.88l5.76-9.97M69.06 27.64v11.51M108.96 50.66l-9.97 5.75M115.13 73.69h-11.51M35.91 73.69H24.4" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M34.8 52.88c-3.02-.92-3.53-.04-1.13 2.03l25.25 21.67c2.89 2.22 6.46 3.39 10.1 3.31l2.87-.22c.71-.02 1.4-.21 2.02-.55a4.56 4.56 0 002.33-3.35c.1-.7.04-1.41-.19-2.09l-.86-3.04c-.57-1.66-1.49-3.17-2.71-4.42a12.01 12.01 0 00-4.34-2.85L34.78 52.88h.02zM6.75 95.84h124.63" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
