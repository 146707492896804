/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_year_built__in_construction': {
    width: 123,
    height: 123,
    viewBox: '0 0 123 123',
    data: '<path pid="0" d="M69.16 6.82C67.01 2.55 61.8.83 57.54 2.98c-1.66.83-3 2.18-3.84 3.84L2.85 108.48c-2.13 4.28-.38 9.48 3.9 11.6 1.19.59 2.5.9 3.83.9h101.66c4.78 0 8.66-3.87 8.66-8.65 0-1.34-.31-2.66-.9-3.86L69.17 6.81l-.01.01z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M52.15 49.62c0 4.38 3.55 7.93 7.93 7.94 4.38 0 7.93-3.55 7.94-7.93 0-4.39-3.55-7.94-7.93-7.94s-7.93 3.55-7.94 7.93zM34.58 71.93c-2.48-5.46 8-11.44 19.34-7.82 12.41 3.94 15.49 10.52 15.49 10.52l1.23 2.65M48.31 78.23l-10.69 26.89M46.05 83.92c3.31 1.49 6.44 3.33 9.35 5.49 4.14 3.21 3.37 15.71 3.37 15.71M34.58 71.93L79.1 92.36M81.86 103.58a5.298 5.298 0 01-4.92-5.63c.04-.63.2-1.24.46-1.82l3.46-7.68a5.28 5.28 0 018.54-1.55c2.36 2.42 4.4 5.13 6.05 8.08 1.46 2.53.59 5.77-1.94 7.22-.49.28-1.03.49-1.58.6-3.31.7-6.69.96-10.07.78zM55.03 64.47l-2.17 5.1" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
