/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_new__unsure': {
    width: 65,
    height: 104,
    viewBox: '0 0 65 104',
    data: '<path pid="0" d="M40.99 62.12c16.3-4.67 25.76-21.66 21.09-38.07C57.41 7.75 40.42-1.71 24.01 2.96 11.81 6.49 3.04 16.98 1.9 29.63c0 1.6 1.14 2.85 2.62 2.85h11.17c1.37 0 2.51-.91 2.74-2.17 1.25-7.64 8.44-12.77 16.07-11.51.46 0 1.03.23 1.48.34 4.79 1.25 8.44 4.9 9.69 9.69 2.05 7.41-2.39 15.05-9.8 17.1-1.14.34-2.28.46-3.42.46h-2.74a5.6 5.6 0 00-5.59 5.59v19.49c0 1.6 1.25 2.74 2.74 2.74h11.17c1.6 0 2.74-1.25 2.74-2.74v-9.46l.23.11h-.01zM24.35 93.93c0 4.67 3.76 8.32 8.32 8.32 4.56 0 8.32-3.76 8.32-8.32 0-4.67-3.76-8.32-8.32-8.32-4.56 0-8.32 3.76-8.32 8.32z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
