/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__detached_house': {
    width: 110,
    height: 106,
    viewBox: '0 0 110 106',
    data: '<path pid="0" d="M89.84 33.58V10.83c0-1.28-1.04-2.32-2.32-2.32h-9.28c-1.28 0-2.32 1.04-2.32 2.32v9.93L56.6 2.99c-.89-.82-2.26-.82-3.15 0L2.39 50.9c-1.32 1.21-.83 3.39.88 3.92.23.07.46.11.7.11h11.6v44.1c0 2.56 2.08 4.64 4.64 4.64h69.63c2.56 0 4.64-2.08 4.64-4.64v-44.1h11.6c1.79 0 2.91-1.93 2.01-3.48-.12-.2-.27-.39-.44-.55L89.83 33.58h.01z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
