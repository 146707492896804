/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'old_heating_energy_consumption__36000_40000': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M1.93 69.68c0 37.31 30.25 67.56 67.56 67.56s67.56-30.25 67.56-67.56S106.8 2.12 69.49 2.12 1.93 32.37 1.93 69.68zM23.43 73.7h11.52M29.6 50.67l9.97 5.76M47.97 34.16l5.76 9.97M69.49 27.64v11.51M109.39 50.66l-9.97 5.75M115.56 73.69h-11.51" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M90.38 38.75c1.15-2.94.31-3.52-1.93-1.28L64.93 61a16.009 16.009 0 00-4.07 9.82v2.88a4.7 4.7 0 00.39 2.06c.29.65.73 1.21 1.27 1.66.55.45 1.19.76 1.88.91.69.15 1.41.15 2.09-.03l3.1-.62c1.69-.44 3.27-1.25 4.62-2.37s2.43-2.52 3.17-4.11l13-32.46v.01zM7.18 95.84h124.63" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
