/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'years_since_old_heating_was_mounted__30_or_more': {
    width: 140,
    height: 139,
    viewBox: '0 0 140 139',
    data: '<path pid="0" d="M22.8 88.05h20.35M32.98 98.22V77.87M8.32 13.71H131.7s5.88 0 5.88 5.88v111.62s0 5.88-5.88 5.88H8.32s-5.88 0-5.88-5.88V19.59s0-5.88 5.88-5.88z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M53.9 103.42c1.44 4.07 5.92 6.99 10.49 6.99 5.79 0 10.49-4.69 10.49-10.49v-1.75c0-5.79-4.69-10.49-10.49-10.49h-.35a9.09 9.09 0 009.09-9.09v-1.4c0-4.83-3.91-8.74-8.74-8.74-2.86 0-5.4 1.37-6.99 3.5M31.82 1.96v29.37M108.2 1.96v29.37M2.45 43.08h135.12M85.6 78.75v21.38c0 5.58 4.63 10.11 10.34 10.11h.59c5.71 0 10.34-4.53 10.34-10.11V78.75c0-5.58-4.63-10.11-10.34-10.11h-.59c-5.71 0-10.34 4.53-10.34 10.11z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
