/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heat_distribution__radiators_and_underfloor_heating': {
    width: 131,
    height: 124,
    viewBox: '0 0 131 124',
    data: '<path pid="0" d="M107.33 38.9V11.85c0-1.52-1.24-2.76-2.76-2.76H93.53c-1.52 0-2.76 1.24-2.76 2.76v11.81L67.79 2.52a2.746 2.746 0 00-3.74 0L3.32 59.5c-1.56 1.44-.98 4.03 1.04 4.67.27.08.55.13.83.13h13.8v52.45c0 3.05 2.47 5.52 5.52 5.52h82.81c3.05 0 5.52-2.47 5.52-5.52V64.3h13.8c2.12 0 3.46-2.3 2.39-4.14-.14-.24-.32-.46-.52-.65l-21.19-20.6.01-.01z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M60.35 71.43a4.458 4.458 0 00-4.455-4.46 4.458 4.458 0 00-4.455 4.46v27.33a4.458 4.458 0 004.455 4.46 4.458 4.458 0 004.455-4.46V71.43z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M69.26 71.43a4.458 4.458 0 00-4.455-4.46 4.457 4.457 0 00-4.455 4.46v27.33a4.457 4.457 0 004.455 4.46 4.458 4.458 0 004.455-4.46V71.43zM78.18 71.43a4.457 4.457 0 00-4.455-4.46 4.458 4.458 0 00-4.455 4.46v27.33a4.458 4.458 0 004.455 4.46 4.457 4.457 0 004.455-4.46V71.43z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="3" d="M87.09 71.43a4.458 4.458 0 00-4.455-4.46 4.458 4.458 0 00-4.455 4.46v27.33a4.458 4.458 0 004.455 4.46 4.458 4.458 0 004.455-4.46V71.43zM46.6 72a1.94 1.94 0 100-3.88 1.94 1.94 0 000 3.88zM25.1 112.72h81.59" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
