/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__apartment': {
    width: 114,
    height: 114,
    viewBox: '0 0 114 114',
    data: '<path pid="0" d="M112.5 112.11H2.34M2.34 112.11V6.85c0-2.94 1.96-4.9 4.9-4.9h68.54c2.94 0 4.9 1.96 4.9 4.9v105.26M80.68 48.46h26.93c2.94 0 4.9 1.96 4.9 4.9v58.75" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M32.94 16.64H18.25v19.58h14.69V16.64zM64.76 16.64H50.07v19.58h14.69V16.64zM32.94 53.36H18.25v19.58h14.69V53.36zM64.76 53.36H50.07v19.58h14.69V53.36zM32.7 112.11V95.95c0-4.9 3.92-8.81 8.81-8.81 4.89 0 8.81 3.92 8.81 8.81v16.16" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
