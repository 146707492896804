/* eslint-disable */
require('./building_heated_area__0_100')
require('./building_heated_area__101_150')
require('./building_heated_area__151_200')
require('./building_heated_area__201_300')
require('./building_heated_area__301_400')
require('./building_heated_area__401_or_more')
require('./building_type__apartment')
require('./building_type__detached_house')
require('./building_type__end_terraced_house')
require('./building_type__middle_terraced_house')
require('./building_type__multi_family_house')
require('./building_type__semi_detached_house')
require('./building_year_built__from_2002')
require('./building_year_built__in_construction')
require('./building_year_built__range_1961_1977')
require('./building_year_built__range_1978_1994')
require('./building_year_built__range_1995_2001')
require('./building_year_built__until_1960')
require('./customer_is_building_owner__no')
require('./customer_is_building_owner__yes')
require('./energy_source_new__air_to_water_heat_pump')
require('./energy_source_new__natural_gas')
require('./energy_source_new__oil')
require('./energy_source_new__other')
require('./energy_source_new__pellets')
require('./energy_source_new__unsure')
require('./energy_source_old__natural_gas')
require('./energy_source_old__none_yet')
require('./energy_source_old__oil')
require('./energy_source_old__other')
require('./existing_problems_with_heating__no')
require('./existing_problems_with_heating__yes')
require('./floor_new_heating__0')
require('./floor_new_heating__1')
require('./heat_distribution__miscellaneous')
require('./heat_distribution__radiator')
require('./heat_distribution__radiators_and_underfloor_heating')
require('./heat_distribution__underfloor_heating')
require('./old_heating_energy_consumption__21000_25000')
require('./old_heating_energy_consumption__26000_30000')
require('./old_heating_energy_consumption__31000_35000')
require('./old_heating_energy_consumption__36000_40000')
require('./old_heating_energy_consumption__41000_45000')
require('./old_heating_energy_consumption__5000_20000')
require('./old_heating_energy_consumption__more_than_45000')
require('./old_heating_energy_consumption__unsure')
require('./people_count__1_2')
require('./people_count__3_4')
require('./people_count__5_6')
require('./people_count__7_8')
require('./people_count__9_or_more')
require('./pipeline_system_exists__miscellaneous')
require('./pipeline_system_exists__no')
require('./pipeline_system_exists__unsure')
require('./pipeline_system_exists__yes')
require('./planned_installation_time__1_to_3_months')
require('./planned_installation_time__12_to_24_months')
require('./planned_installation_time__3_to_6_months')
require('./planned_installation_time__6_to_12_months')
require('./planned_installation_time__asap')
require('./planned_installation_time__no_installation')
require('./pv_system_existing_or_planned__already_installed')
require('./pv_system_existing_or_planned__interested')
require('./pv_system_existing_or_planned__not_interested')
require('./pv_system_existing_or_planned__unsure')
require('./water_heating__unsure')
require('./years_since_old_heating_was_mounted__0_9')
require('./years_since_old_heating_was_mounted__10_19')
require('./years_since_old_heating_was_mounted__20_29')
require('./years_since_old_heating_was_mounted__30_or_more')
require('./years_since_old_heating_was_mounted__age_of_old_heating_unknown')
require('./years_since_old_heating_was_mounted__no_heating_installed')
