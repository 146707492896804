/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'old_heating_energy_consumption__41000_45000': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M136.61 69.68c0 37.31-30.25 67.56-67.56 67.56S1.49 106.99 1.49 69.68 31.74 2.12 69.05 2.12s67.56 30.25 67.56 67.56zM86.33 43.78l5.76-9.97M45.28 33.91l5.76 9.97M69.06 39.15V27.64M39.13 56.41l-9.97-5.75M34.49 73.69H22.98M113.71 73.69H102.2" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M69.96 63.37a11.882 11.882 0 00-7.05 7.27l-.86 3.04c-.22.67-.29 1.39-.19 2.09.1.7.36 1.37.77 1.95.4.58.94 1.06 1.56 1.4.62.34 1.31.53 2.02.55l2.87.22a16 16 0 0010.1-3.31l25.25-21.67c2.4-2.06 1.89-2.95-1.13-2.03L69.94 63.37h.02zM131.36 95.84H6.74" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
