<template>
    <div>
        <div class="controls"
             v-show="canNavigateNextStep || canNavigatePrevStep">
            <div class="controls__container--left">
                <button class="t-btn-2 controls__button controls__button--prev"
                        v-if="canNavigatePrevStep"
                        @click="goBack"
                        tabindex="98">
                    <ArrowSvg />Zurück
                </button>
            </div>
            <div class="controls__container--right">
                <button class="t-btn-1 controls__button controls__button--next"
                        :class="{ 't-btn-is-loading': loading || redirecting }"
                        type="submit"
                        v-if="canNavigateNextStep"
                        @click="goNext"
                        :disabled="loading || redirecting"
                        tabindex="99">
                    {{ ctaLabel }} <ArrowSvg class="controls__container--right__icon"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import navCommonMixin from '@/mixins/mixin.navCommon';
import { eT } from '@/utils/eventTracking';
import ArrowSvg from '../../public/svg/back.svg';

export default {
    inject: ['$validator'],
    mixins: [navCommonMixin],
    components: {
        ArrowSvg,
    },
    computed: {
        ...mapState('ui', [
            'loading',
            'redirecting',
            'ctaLabel',
            'canNavigateNextStep',
        ]),
    },
    methods: {
        goBack() {
            eT({ event: 'click_back' });
            this.navigatePrevStep();
            this.$store.commit('ui/setShowErrorInfoBox', false);
        },
        goNext() {
            eT({ event: 'click_forward' });
            this.navigateNextStep();
        },
    },
};
</script>
