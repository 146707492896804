/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'customer_is_building_owner__yes': {
    width: 137,
    height: 136,
    viewBox: '0 0 137 136',
    data: '<path pid="0" d="M48.447 48.447v25.865a2.88 2.88 0 002.876 2.876h45.979a2.88 2.88 0 002.876-2.876V48.447M31.21 39.828L72.433 4.1a2.88 2.88 0 013.757 0l41.224 35.727M91.55 2.467h11.494a2.88 2.88 0 012.876 2.877v11.494M5.344 82.93H22.58s2.876 0 2.876 2.877v40.237s0 2.876-2.876 2.876H5.344s-2.877 0-2.877-2.876V85.817s0-2.876 2.877-2.876M25.457 120.653c60.35 20.114 40.227 20.114 109.196-14.371a15.86 15.86 0 00-17.238-5.752l-24.809 7.856" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M25.457 88.674h17.237c13.52 0 22.99 11.494 25.866 11.494h20.113c5.753 0 5.753 11.495 0 11.495H57.065M82.93 57.065a2.88 2.88 0 00-2.875-2.876H68.56a2.88 2.88 0 00-2.876 2.876V77.18H82.92V57.065h.01z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
