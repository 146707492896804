<template>
    <div class="data-disclaimer" v-if="this.$route.params.id === 'anfragen'">
        <lock/>
        <div>
            SSL VERSCHLÜSSELUNG. Auf Ihre Anfrage hin verarbeiten wir Ihre Angaben
            ausschließlich zur Erstellung eines kostenlosen und unverbindlichen Angebots
            und geben diese NICHT an Dritte weiter oder nutzen diese für unerwünschte
            Werbung. Weitere Informationen finden Sie in unserer
            <a href="https://www.thermondo.de/intern/datenschutz/"
               @click.prevent="showModalTerms()">Datenschutzerklärung</a>.
        </div>
    </div>
</template>

<script>
import Lock from '../../public/svg/lock.svg';

export default {
    components: {
        Lock,
    },
    methods: {
        showModalTerms() {
            this.$store.dispatch('ui/modal/terms/show');
        },
    },
};
</script>
