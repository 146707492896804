/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_year_built__until_1960': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M7.52 13.71H130.9s5.88 0 5.88 5.88v111.62s0 5.88-5.88 5.88H7.52s-5.88 0-5.88-5.88V19.59s0-5.88 5.88-5.88zM31.02 1.96v29.37M107.39 1.96v29.37M1.64 43.08h135.12" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M48.54 102.88V76.73v2.18c0 2.41-1.95 4.36-4.36 4.36H42M55.08 102.88H42M31.19 81.9l-9 8.99M22.19 90.89l9 9M72.03 83.26v1.09c0 3.61-2.93 6.54-6.54 6.54s-6.54-2.93-6.54-6.54v-1.09c0-3.61 2.93-6.54 6.54-6.54s6.54 2.93 6.54 6.54zm0 0v13.08c0 3.61-2.93 6.54-6.54 6.54-2.85 0-5.64-1.82-6.54-4.36M80.01 96.34v-1.09c0-3.61 2.93-6.54 6.54-6.54s6.54 2.93 6.54 6.54v1.09c0 3.61-2.93 6.54-6.54 6.54s-6.54-2.93-6.54-6.54zm0 0V83.26c0-3.61 2.93-6.54 6.54-6.54 2.85 0 5.64 1.82 6.54 4.36M100.74 82.76v13.36c0 3.49 2.89 6.32 6.46 6.32h.37c3.57 0 6.46-2.83 6.46-6.32V82.76c0-3.49-2.89-6.32-6.46-6.32h-.37c-3.57 0-6.46 2.83-6.46 6.32z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
