/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'planned_installation_time__no_installation': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M66.61 101.94c0 19.47 15.78 35.25 35.25 35.25s35.25-15.78 35.25-35.25-15.78-35.25-35.25-35.25-35.25 15.78-35.25 35.25zM114.32 89.48L89.4 114.4M114.32 114.41L89.4 89.49" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M54.86 107.81h-47c-3.24 0-5.88-2.63-5.88-5.88V19.68c0-3.24 2.63-5.88 5.88-5.88h105.75c3.24 0 5.88 2.63 5.88 5.88v38.19M31.36 2.07v29.37M90.11 2.07v29.37M1.99 43.19h117.5" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
