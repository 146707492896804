/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'years_since_old_heating_was_mounted__20_29': {
    width: 140,
    height: 139,
    viewBox: '0 0 140 139',
    data: '<path pid="0" d="M8.3 13.71h123.38s5.88 0 5.88 5.88v111.62s0 5.88-5.88 5.88H8.3s-5.88 0-5.88-5.88V19.59s0-5.88 5.88-5.88zM31.8 1.96v29.37M108.17 1.96v29.37M2.42 43.08h135.12M62.73 88.57h12.72" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M114.51 83.26v1.09c0 3.61-2.93 6.54-6.54 6.54s-6.54-2.93-6.54-6.54v-1.09c0-3.61 2.93-6.54 6.54-6.54s6.54 2.93 6.54 6.54zm0 0v13.08c0 3.61-2.93 6.54-6.54 6.54-2.85 0-5.64-1.82-6.54-4.36M40.92 82.76v13.36c0 3.49 2.89 6.32 6.46 6.32h.37c3.57 0 6.46-2.83 6.46-6.32V82.76c0-3.49-2.89-6.32-6.46-6.32h-.37c-3.57 0-6.46 2.83-6.46 6.32zM33.85 102.78H20.88v-4.2c0-2.31 1.22-4.44 3.22-5.6l6.53-3.81a6.483 6.483 0 003.22-5.6v-.24c0-3.58-2.9-6.48-6.48-6.48-2.82 0-5.59 1.8-6.48 4.32M95.2 102.78H82.23v-4.2c0-2.31 1.22-4.44 3.22-5.6l6.53-3.81a6.483 6.483 0 003.22-5.6v-.24c0-3.58-2.9-6.48-6.48-6.48-2.82 0-5.59 1.8-6.48 4.32" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
