/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'old_heating_energy_consumption__31000_35000': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M1.69 69.68c0 37.31 30.25 67.56 67.56 67.56s67.56-30.25 67.56-67.56S106.56 2.12 69.25 2.12 1.69 32.37 1.69 69.68zM23.18 73.7H34.7M29.36 50.67l9.97 5.76M109.14 50.66l-9.97 5.75M115.32 73.69h-11.51" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M61.21 65.09a11.887 11.887 0 002.89 9.7l2.23 2.24c.48.52 1.07.93 1.73 1.18a4.547 4.547 0 004.06-.4c.6-.38 1.1-.89 1.47-1.49l1.6-2.4c1.85-3.14 2.58-6.82 2.06-10.43l-6.52-32.62c-.62-3.11-1.64-3.1-2.32 0l-7.19 34.22h-.01zM6.94 95.84h124.62M88.31 44.13l5.76-9.97M47.73 34.16l5.76 9.97" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
