/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'planned_installation_time__3_to_6_months': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M7.45 14.05h123.38s5.88 0 5.88 5.88v111.62s0 5.88-5.88 5.88H7.45s-5.88 0-5.88-5.88V19.93s0-5.88 5.88-5.88zM30.95 2.3v29.37M107.33 2.3v29.37M1.58 43.43H136.7M60.81 88h19.05" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M27.85 103.06c1.34 3.8 5.53 6.53 9.79 6.53 5.41 0 9.79-4.38 9.79-9.79v-1.63c0-5.41-4.38-9.79-9.79-9.79h-.33c4.69 0 8.49-3.8 8.49-8.49v-1.31c0-4.51-3.65-8.16-8.16-8.16-2.67 0-5.04 1.28-6.53 3.26M92.75 100.18v-1.63c0-5.41 4.38-9.79 9.79-9.79 5.41 0 9.79 4.38 9.79 9.79v1.63c0 5.41-4.38 9.79-9.79 9.79-5.41 0-9.79-4.38-9.79-9.79zm0 0V80.6c0-5.41 4.38-9.79 9.79-9.79 4.26 0 8.45 2.72 9.79 6.53" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
