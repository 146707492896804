/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'floor_new_heating__0': {
    width: 133,
    height: 124,
    viewBox: '0 0 133 124',
    data: '<g clip-path="url(#clip0_3175_596)" _stroke="#000" stroke-width="3" stroke-linecap="round"><path pid="0" d="M94.69 27.12V8.44c0-1.05-.85-1.91-1.91-1.91h-7.62c-1.05 0-1.91.85-1.91 1.91v8.16L67.39 2.01c-.73-.67-1.85-.67-2.58 0L22.88 41.34c-1.08.99-.68 2.78.72 3.22.19.06.38.09.58.09h9.53v73.98c0 2.11 1.71 3.81 3.81 3.81h57.17c2.1 0 3.81-1.71 3.81-3.81V44.65h9.53c1.47 0 2.39-1.59 1.65-2.86-.1-.17-.22-.32-.36-.45L94.69 27.12zM1.5 82.21h129.2M78.1 72.24c0 1.51-1.21 2.73-2.71 2.73" stroke-linejoin="round"/><path pid="1" d="M54.1 58.04v2.91M54.1 65.11v2.91M78.1 58.04v2.91M78.1 65.11v2.91"/><path pid="2" d="M56.81 74.97c-1.5 0-2.71-1.22-2.71-2.73M56.81 50.97c-1.5 0-2.71 1.22-2.71 2.73M75.39 50.97c1.5 0 2.71 1.22 2.71 2.73" stroke-linejoin="round"/><path pid="3" d="M61.73 50.97h2.21M68.58 50.97h2.21M61.73 74.97h2.21M68.58 74.97h2.21"/><path pid="4" d="M130.26 72.24c0 1.51-1.21 2.73-2.71 2.73" stroke-linejoin="round"/><path pid="5" d="M106.26 58.04v2.91M106.26 65.11v2.91M130.26 58.04v2.91M130.26 65.11v2.91"/><path pid="6" d="M108.97 74.97c-1.5 0-2.71-1.22-2.71-2.73M108.97 50.97c-1.5 0-2.71 1.22-2.71 2.73M127.56 50.97c1.5 0 2.71 1.22 2.71 2.73" stroke-linejoin="round"/><path pid="7" d="M113.9 50.97h2.21M120.75 50.97h2.21M113.9 74.97h2.21M120.75 74.97h2.21"/><path pid="8" d="M78.1 111.3c0 1.51-1.21 2.73-2.71 2.73" stroke-linejoin="round"/><path pid="9" d="M54.1 97.1v2.91M54.1 104.17v2.91M78.1 97.1v2.91M78.1 104.17v2.91"/><path pid="10" d="M56.81 114.03c-1.5 0-2.71-1.22-2.71-2.73M56.81 90.03c-1.5 0-2.71 1.22-2.71 2.73M75.39 90.03c1.5 0 2.71 1.22 2.71 2.73" stroke-linejoin="round"/><path pid="11" d="M61.73 90.03h2.21M68.58 90.03h2.21M61.73 114.03h2.21M68.58 114.03h2.21"/><path pid="12" d="M20.26 66.7c0 3.53-2.86 6.39-6.39 6.39s-6.39-2.86-6.39-6.39 6.39-25.57 6.39-25.57 6.39 22.04 6.39 25.57zM13.87 73.09v8.52" stroke-linejoin="round"/></g><defs><clipPath id="clip0_3175_596"><path pid="13" _fill="#fff" d="M0 0h132.2v123.95H0z"/></clipPath></defs>'
  }
})
