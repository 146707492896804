/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_old__other': {
    width: 92,
    height: 23,
    viewBox: '0 0 92 23',
    data: '<path pid="0" d="M1.51 11.16c0 5.33 4.31 9.55 9.55 9.55 5.24 0 9.55-4.31 9.55-9.55 0-5.33-4.31-9.55-9.55-9.55-5.24 0-9.55 4.31-9.55 9.55zM36.04 11.16c0 5.33 4.31 9.55 9.55 9.55 5.24 0 9.55-4.31 9.55-9.55 0-5.24-4.31-9.55-9.55-9.55-5.24 0-9.55 4.31-9.55 9.55zM70.5 11.16c0 5.33 4.31 9.55 9.55 9.55 5.24 0 9.55-4.31 9.55-9.55 0-5.33-4.31-9.63-9.55-9.63-5.24 0-9.55 4.31-9.55 9.55v.08z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
