/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'years_since_old_heating_was_mounted__0_9': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M7.76 14.05h123.38s5.88 0 5.88 5.88v111.62s0 5.88-5.88 5.88H7.76s-5.88 0-5.88-5.88V19.93s0-5.88 5.88-5.88zM31.26 2.3v29.37M107.63 2.3v29.37M1.88 43.43H137M61.11 88h19.05" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M112.63 80.6v1.63c0 5.41-4.38 9.79-9.79 9.79-5.41 0-9.79-4.38-9.79-9.79V80.6c0-5.41 4.38-9.79 9.79-9.79 5.41 0 9.79 4.38 9.79 9.79zm0 0v19.58c0 5.41-4.38 9.79-9.79 9.79-4.26 0-8.45-2.72-9.79-6.53M26.94 80.27v20.01c0 5.23 4.33 9.46 9.67 9.46h.55c5.34 0 9.67-4.24 9.67-9.46V80.27c0-5.23-4.33-9.46-9.67-9.46h-.55c-5.34 0-9.67 4.24-9.67 9.46z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
