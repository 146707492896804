/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pv_system_existing_or_planned__already_installed': {
    width: 155,
    height: 125,
    viewBox: '0 0 155 125',
    data: '<path pid="0" d="M54.84 2.14v5.88M13.71 13.9l5.88 5.87M95.96 13.89l-5.88 5.88M1.96 55.02h5.87M81.19 31.65C68.26 17.09 45.98 15.78 31.43 28.7a35.235 35.235 0 00-11.84 26.31c0 3.92.66 7.81 1.96 11.51M102.09 107.89H18.7c-1.62 0-2.94-1.32-2.94-2.94 0-.5.13-.99.37-1.43l26.11-47c.52-.93 1.5-1.51 2.57-1.51h61.17c1.07 0 2.05.58 2.57 1.51l10.78 19.41M63.65 55.02L51.9 107.9M87.15 55.02L98.9 107.9M33.29 72.64h84.21M23.5 90.27h81.03M110.92 101.91c0 16.16 17.5 26.26 31.49 18.18 6.5-3.75 10.5-10.68 10.5-18.18 0-16.16-17.5-26.26-31.49-18.18a20.986 20.986 0 00-10.5 18.18z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M142.53 93.27l-11.29 15.06a2.908 2.908 0 01-4.39.31l-5.83-5.83" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
