import collectedData from './collectedData';
import settings from './settings';
import status from './status';
import ui from './ui';

export default {
    collectedData,
    settings,
    status,
    ui,
};
