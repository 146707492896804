/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_old__none_yet': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M1.88 69.95c0 37.31 30.25 67.56 67.56 67.56S137 107.26 137 69.95 106.75 2.39 69.44 2.39 1.88 32.64 1.88 69.95zM21.67 22.18l95.55 95.55" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
