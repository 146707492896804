/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people_count__7_8': {
    width: 133,
    height: 129,
    viewBox: '0 0 133 129',
    data: '<path pid="0" d="M2.1 57.19h129.25v47.12H2.1V57.19zM67.56 27.49c6.95 0 12.59-5.64 12.59-12.59S74.51 2.31 67.56 2.31 54.97 7.95 54.97 14.9s5.64 12.59 12.59 12.59zM46.1 45.92c1.06-1.95 2.41-3.75 4.01-5.35a23.484 23.484 0 0116.62-6.88 23.526 23.526 0 0120.63 12.23M108.69 27.49c6.95 0 12.59-5.64 12.59-12.59s-5.64-12.59-12.59-12.59S96.1 7.95 96.1 14.9s5.64 12.59 12.59 12.59z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M131.35 57.19c0-6.23-2.48-12.21-6.88-16.62a23.484 23.484 0 00-16.62-6.88c-6.24 0-12.21 2.48-16.62 6.88a23.484 23.484 0 00-6.88 16.62M26.44 27.49c6.95 0 12.59-5.64 12.59-12.59S33.39 2.31 26.44 2.31 13.85 7.95 13.85 14.9s5.64 12.59 12.59 12.59zM49.1 57.19c0-6.23-2.48-12.21-6.88-16.62a23.484 23.484 0 00-16.62-6.88c-6.24 0-12.21 2.48-16.62 6.88A23.484 23.484 0 002.1 57.19M56.05 111.34l2.25 16.01h17.14l1.97-16.01M93.41 111.34l2.25 16.01h17.14l1.97-16.01M18.68 111.34l2.25 16.01h17.14l1.97-16.01M61.2 79.32h12.72M39.43 68.06h13.33l-.33.56a44.49 44.49 0 00-6.33 22.87v3.25M98.2 86.57v1.16a6.99 6.99 0 01-13.98 0v-1.16a6.99 6.99 0 0113.98 0z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M97.04 73.76V72.6a5.819 5.819 0 10-11.64 0v1.16a5.82 5.82 0 1011.64 0z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
