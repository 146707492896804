/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'planned_installation_time__12_to_24_months': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M7.67 14.05h123.38s5.88 0 5.88 5.88v111.62s0 5.88-5.88 5.88H7.67s-5.88 0-5.88-5.88V19.93s0-5.88 5.88-5.88zM31.17 2.3v29.37M107.55 2.3v29.37M1.8 43.43h135.12M33.94 75.74l13.47 13.47M33.94 102.68l13.47-13.47" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M67.53 107.58V68.41v3.26c0 3.61-2.92 6.53-6.53 6.53h-3.26M77.33 107.58H57.75M104.78 107.58H85.2v-6.34c0-3.48 1.85-6.7 4.86-8.46l9.87-5.76a9.79 9.79 0 004.86-8.46v-.36c0-5.41-4.38-9.79-9.79-9.79-4.26 0-8.45 2.72-9.79 6.53" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
