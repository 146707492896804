/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__multi_family_house': {
    width: 114,
    height: 116,
    viewBox: '0 0 114 116',
    data: '<path pid="0" d="M5.78 50.66v61.2c0 1.35 1.1 2.45 2.45 2.45h97.92c1.35 0 2.45-1.1 2.45-2.45v-61.2" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M35.29 50.66c1.25 0 2.31-.93 2.45-2.17 1.18-10.75 10.86-18.51 21.61-17.32 9.12 1 16.32 8.2 17.32 17.32a2.45 2.45 0 002.45 2.17h30.79c1.35 0 2.45-1.09 2.45-2.44 0-.31-.06-.62-.18-.91L94.56 3.25a2.458 2.458 0 00-2.31-1.54H22.13c-1 0-1.9.61-2.28 1.54L2.23 47.31a2.451 2.451 0 002.27 3.36h30.78l.01-.01z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M64.53 53.11c0-4.06-3.29-7.34-7.34-7.34s-7.34 3.29-7.34 7.34V67.8c0 1.35 1.1 2.45 2.45 2.45h9.79c1.35 0 2.45-1.1 2.45-2.45V53.11h-.01zM49.85 114.31V97.17c0-4.06 3.29-7.34 7.34-7.34s7.34 3.29 7.34 7.34v17.14M81.67 84.93h14.69s2.45 0 2.45 2.45v9.79s0 2.45-2.45 2.45H81.67s-2.45 0-2.45-2.45v-9.79s0-2.45 2.45-2.45zM81.67 60.46h14.69s2.45 0 2.45 2.45v9.79s0 2.45-2.45 2.45H81.67s-2.45 0-2.45-2.45v-9.79s0-2.45 2.45-2.45zM18.02 84.93h14.69s2.45 0 2.45 2.45v9.79s0 2.45-2.45 2.45H18.02s-2.45 0-2.45-2.45v-9.79s0-2.45 2.45-2.45zM18.02 60.46h14.69s2.45 0 2.45 2.45v9.79s0 2.45-2.45 2.45H18.02s-2.45 0-2.45-2.45v-9.79s0-2.45 2.45-2.45z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
