<template>
    <div>
        <div class="inquire">
            <zipcode-success/>
            <customer-referral-badge/>
            <div class="inquire__form__wrapper">
                <form @submit.prevent="submit" class="inquire__form">
                    <div
                        class="inquire__form__radio-wrapper"
                        :class="{ 'form-field-error__container': errors.has('salutation') }">
                        <div class="radio radio--inline">
                            <input type="radio"
                                   tabindex="0"
                                   id="herr"
                                   name="salutation"
                                   v-model="salutation"
                                   value="Herr"
                                   v-validate="'required'"
                                   data-vv-validate-on="input"
                                   v-focus-on-invalid>
                            <label for="herr">Herr</label>
                        </div>
                        <div class="radio radio--inline">
                            <input type="radio"
                                   id="frau"
                                   name="salutation"
                                   v-model="salutation"
                                   value="Frau"
                                   v-validate="'required'"
                                   data-vv-validate-on="input">
                            <label for="frau">Frau</label>
                        </div>
                        <div class="form-field-error__text"
                             v-show="errors.has('salutation')">
                            {{ errors.first('salutation') }}
                        </div>
                    </div>
                    <div class="inquire__form__input">
                        <div class="text-input">
                            <div>
                                <input type="text"
                                       name="first_name"
                                       v-validate="'required'"
                                       v-model.trim="first_name"
                                       id="first_name"
                                       :disabled="loading"
                                       placeholder=""
                                       v-focus-on-invalid
                                >
                                <label for="first_name">Vorname</label>
                            </div>
                            <div
                                class="text-input__supporting-text"
                                :class="{ 'text-input__supporting-text--error':
                                     errors.has('first_name') }"
                            >
                                {{ validationList.custom.first_name.required }}
                            </div>
                        </div>
                    </div>
                    <div class="inquire__form__input">
                        <div class="text-input">
                            <div>
                                <input type="text"
                                       name="last_name"
                                       v-model.trim="last_name"
                                       v-validate="'required'"
                                       :disabled="loading"
                                       id="last_name"
                                       placeholder=""
                                       v-focus-on-invalid
                                >
                                <label for="last_name">Nachname</label>
                            </div>
                            <div
                                class="text-input__supporting-text"
                                :class="{ 'text-input__supporting-text--error':
                                     errors.has('last_name') }"
                            >
                                {{ validationList.custom.last_name.required }}
                            </div>
                        </div>
                    </div>
                    <div class="inquire__form__input">
                        <div class="text-input">
                            <div>
                                <input type="text"
                                       name="phone"
                                       v-model.trim="phone"
                                       v-validate="'required'"
                                       id="phone"
                                       inputmode="tel"
                                       :disabled="loading"
                                       placeholder=""
                                       v-focus-on-invalid
                                >
                                <label for="phone">Telefonnummer</label>
                            </div>
                            <div
                                class="text-input__supporting-text"
                                :class="{ 'text-input__supporting-text--error':
                                     errors.has('phone') }"
                            >
                                {{ validationList.custom.phone.required }}
                            </div>
                        </div>
                    </div>
                    <div class="inquire__form__input">

                        <div class="text-input">
                            <div>
                                <input type="text"
                                       name="email"
                                       v-model.trim="email"
                                       v-validate="'required|email|max:80'"
                                       id="email"
                                       inputmode="email"
                                       :disabled="loading"
                                       placeholder=""
                                       v-focus-on-invalid>
                                <label for="email">E-Mail</label>
                            </div>
                            <div
                                class="text-input__supporting-text"
                                :class="{ 'text-input__supporting-text--error':
                                     errors.has('email') }"
                            >
                                {{ validationList.custom.email.required }}
                            </div>
                        </div>
                    </div>
                    <div class="inquire__form__checkbox-wrapper">
                        <div v-if="isInFebesolArea" :class="{
                        'form-field-error__container': errors.has('terms_privacy_febesol')
                        }">
                            <div class="form-field-error__text"
                                 v-show="errors.has('terms_privacy_febesol')">
                                {{ errors.first('terms_privacy_febesol') }}
                            </div>
                            <div class="checkbox term">
                                <input type="checkbox"
                                       name="terms_privacy_febesol"
                                       id="terms_privacy_febesol"
                                       :disabled="loading"
                                       @click="saveTermItem($event)">
                                <label
                                    class="inquire__form__terms-text"
                                    for="terms_privacy_febesol"
                                    id="terms_privacy_febesol_text">
                                    Ja, ich möchte über das telefonische Beratungsgespräch zu
                                    meiner konkreten Anfrage hinaus auch telefonische Informationen
                                    von der Thermondo GmbH und deren verbundenem Unternehmen,
                                    der FEBESOL GmbH zu weiteren Angeboten und Dienstleistungen,
                                    wie bspw. Photovoltaik erhalten.
                                </label>
                            </div>
                        </div>
                        <div
                            :class="{ 'form-field-error__container': errors.has('terms_contact') }">
                            <div class="checkbox term">
                                <input type="checkbox"
                                       name="terms_contact"
                                       id="terms_contact"
                                       :disabled="loading"
                                       @click="saveTermItem($event)">
                                <label
                                    class="inquire__form__terms-text"
                                    for="terms_contact"
                                    id="terms_contact_text">
                                    Ja, die Thermondo GmbH darf mir Tipps und Informationen
                                    rund um Heizungswechsel und Photovoltaik zuschicken.
                                    Abmeldung ist jederzeit möglich.
                                </label>
                            </div>
                        </div>
                        <div class="form-field-error__text"
                             v-show="errors.has('terms_contact')">
                            {{ errors.first('terms_contact') }}
                        </div>
                    </div>
                    <ErrorInfoBox v-if="showErrorInfoBox"/>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import navCommonMixin from '@/mixins/mixin.navCommon';
import behaviourModifiers from '@/mixins/mixin.behaviourModifiers';
import CustomerReferralBadge from '@/components/CustomerReferralBadge.vue';
import ErrorInfoBox from '@/components/ErrorInfoBox.vue';
import febesolZipcodesList from '../config/febesol-zipcodes.json';
import validationList from '../config/validation.json';
import ZipcodeSuccess from '@/components/ZipcodeSuccess.vue';

export default {

    inject: ['$validator'],
    mixins: [
        navCommonMixin,
        behaviourModifiers,
    ],
    mounted() {
        this.applyBehaviourModifiers();
    },
    data() {
        return {
            validationList,
        };
    },
    methods: {
        saveTermItem(event) {
            if (event.target.checked) {
                this.$store.dispatch('collectedData/customer/addTerm', {
                    name: event.target.name,
                    text: _.trim(
                        document.getElementById(`${event.target.id}_text`).innerHTML,
                    ),
                });
            } else {
                this.$store.dispatch(
                    'collectedData/customer/removeTerm',
                    event.target.name,
                );
            }
        },
    },
    computed: {
        ...mapState('ui', [
            'loading',
            'showErrorInfoBox',
        ]),
        ...mapFields('collectedData/customer', [
            'salutation',
            'first_name',
            'last_name',
            'phone',
            'email',
        ]),
        isInFebesolArea() {
            const {
                pv_system_existing_or_planned: pvSystem,
                building_type: buildingType,
            } = this.$store.state.collectedData.answers;

            const { zipcode } = this.$store.state.collectedData.customer;
            // eslint-disable-next-line max-len
            const isZipcodeExist = febesolZipcodesList.zipcodesList.includes(parseInt(zipcode, 10));
            if (isZipcodeExist) {
                return (pvSystem === 'interested' || pvSystem === 'unsure') && buildingType !== 'apartment';
            }
            return false;
        },
    },
    components: {
        ZipcodeSuccess,
        CustomerReferralBadge,
        ErrorInfoBox,
    },
    directives: {
        focusOnInvalid: {
            inserted(el, binding, vnode) {
                vnode.context.$store.dispatch('ui/fields/focusAndScrollToOnError/register', {
                    name: el.name,
                    el,
                    data: {},
                });
            },
        },
    },
};
</script>
