/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'existing_problems_with_heating__yes': {
    width: 125,
    height: 111,
    viewBox: '0 0 125 111',
    data: '<path pid="0" d="M20.36 36.11c-1.03 0-2.06-.2-3.01-.6-.96-.4-1.82-.98-2.55-1.71-.73-.73-1.31-1.6-1.71-2.55-.4-.95-.6-1.98-.6-3.01 0-1.03.2-2.06.6-3.01.4-.96.98-1.82 1.71-2.55.73-.73 1.6-1.31 2.55-1.71.95-.4 1.98-.6 3.01-.6h5.25c-2.09 0-4.09-.83-5.57-2.31a7.871 7.871 0 01-2.31-5.57c0-4.35 3.53-5.25 7.87-5.25h34.12c14.97 0 18.37 2.62 36.74 5.25v44.62c-12.86 0-34.12 23.62-34.12 44.62 0 8.3-11.49 11.39-15.75-3.77-2.62-9.35 5.25-27.72 5.25-27.72H9.85c-2.09 0-4.09-.83-5.57-2.31a7.872 7.872 0 01-2.31-5.57c0-4.35 3.53-10.5 7.87-10.5h5.25c-2.09 0-4.09-.83-5.57-2.31a7.872 7.872 0 010-11.14 7.871 7.871 0 015.57-2.31h5.25M96.47 64.99h26.25V2H96.47v62.99z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M108.28 12.49c.72 0 1.31.59 1.31 1.31 0 .72-.59 1.31-1.31 1.31M108.28 12.49c-.72 0-1.31.59-1.31 1.31 0 .72.59 1.31 1.31 1.31" _stroke="#000" stroke-width="3"/>'
  }
})
