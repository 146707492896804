/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_year_built__range_1978_1994': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M7.74 13.71h123.38s5.88 0 5.88 5.88v111.62s0 5.88-5.88 5.88H7.74s-5.88 0-5.88-5.88V19.59s0-5.88 5.88-5.88zM31.24 1.96v29.37M107.62 1.96v29.37M1.87 43.08h135.12M60.47 88.57h12.72" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M20.59 76.21h13.33l-.33.56a44.49 44.49 0 00-6.33 22.87v3.25M96.75 82.03v1.09c0 3.61-2.93 6.54-6.54 6.54s-6.54-2.93-6.54-6.54v-1.09c0-3.61 2.93-6.54 6.54-6.54s6.54 2.93 6.54 6.54zm0 0v13.08c0 3.61-2.93 6.54-6.54 6.54-2.85 0-5.64-1.82-6.54-4.36M121.76 94.96h-17.81l13.36-20.04v26.72M53.05 94.73v1.16a6.99 6.99 0 01-13.98 0v-1.16a6.99 6.99 0 0113.98 0z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M51.88 81.91v-1.16a5.819 5.819 0 10-11.64 0v1.16a5.82 5.82 0 1011.64 0z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
