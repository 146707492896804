/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__101_150': {
    width: 134,
    height: 133,
    viewBox: '0 0 134 133',
    data: '<path pid="0" d="M2.34 49.91V13.06s0-11.25 12.12-11.25h105.08s12.12 0 12.12 11.25M131.66 13.06V119.9s0 11.25-12.12 11.25h-38M70.58 68.84v6" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M70.58 86.78v38.44s0 5.93-6.4 5.93H8.73s-6.4 0-6.4-5.93V68.84s0-5.93 6.4-5.93h53.26" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="11.94 11.94"/><path pid="2" d="M67.81 63.86c1.45.8 2.77 2.27 2.77 4.98" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
