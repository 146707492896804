/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_new__air_to_water_heat_pump': {
    width: 117,
    height: 82,
    viewBox: '0 0 117 82',
    data: '<path pid="0" d="M11.86 1.98h93.02s9.79 0 9.79 9.79v58.75s0 9.79-9.79 9.79H11.86s-9.79 0-9.79-9.79V11.77s0-9.79 9.79-9.79z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M12.74 41.15c0 13.5 10.94 24.44 24.44 24.44 13.5 0 24.44-10.94 24.44-24.44 0-13.5-10.94-24.44-24.44-24.44-13.5 0-24.44 10.94-24.44 24.44z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M53.46 44.96a6.354 6.354 0 00-3.68-3.89l-7.6-3.03 1.26-7.58a6.362 6.362 0 00-5.25-7.31 6.362 6.362 0 00-7.31 7.31l1.26 7.58-7.6 3.03a6.354 6.354 0 00-3.52 8.28 6.354 6.354 0 008.28 3.52c.64-.26 1.23-.61 1.75-1.06l6.12-5.25 6.12 5.25a6.36 6.36 0 0010.18-6.85h-.01z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="3" d="M31.92 40.71a5.25 5.25 0 1010.5 0 5.25 5.25 0 10-10.5-.01M74.01 23.94h26.6M74.01 35.17h26.6M74.01 46.41h26.6" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
