/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__semi_detached_house': {
    width: 125,
    height: 70,
    viewBox: '0 0 125 70',
    data: '<path pid="0" d="M62.22 25.11v39.8c0 .37.12.71 0 1.04a3.014 3.014 0 002.83 1.99h45.43c1.67 0 3.03-1.36 3.03-3.03V36.14h7.57c1.17 0 1.9-1.26 1.31-2.27-.08-.13-.17-.25-.29-.36l-11.63-11.3V7.37c0-.84-.68-1.51-1.51-1.51h-6.06c-.84 0-1.51.68-1.51 1.51v6.48L88.78 2.25a1.5 1.5 0 00-2.05 0L62.36 25.11" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M62.38 25.11l-2.99-2.91V7.36c0-.84-.68-1.51-1.51-1.51h-6.06c-.84 0-1.51.68-1.51 1.51v6.48L37.7 2.24a1.5 1.5 0 00-2.05 0L2.34 33.5a1.514 1.514 0 001.03 2.63h7.57V64.9c0 1.67 1.36 3.03 3.03 3.03H59.4c1.31 0 2.41-.83 2.83-1.99-.12-.32 0-.67 0-1.04V25.1" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M62.22 25.11v39.8m0 0c0 .37-.12.71 0 1.04.12-.32 0-.67 0-1.04z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
