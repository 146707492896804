/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'old_heating_energy_consumption__26000_30000': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M1.61 69.68c0 37.31 30.25 67.56 67.56 67.56s67.56-30.25 67.56-67.56S106.48 2.12 69.17 2.12 1.61 32.37 1.61 69.68zM23.1 73.7h11.52M29.27 50.67l9.97 5.76M69.16 27.64v11.51M109.06 50.66l-9.97 5.75M115.23 73.69h-11.51" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M59.65 71.21c.74 1.59 1.82 2.99 3.17 4.11 1.35 1.12 2.92 1.93 4.62 2.37l3.1.62c.69.17 1.4.18 2.09.03a4.517 4.517 0 003.15-2.57c.29-.65.42-1.35.39-2.06v-2.88c-.2-3.64-1.64-7.11-4.07-9.82L48.58 37.48c-2.24-2.24-3.09-1.66-1.93 1.28l13 32.46v-.01zM6.86 95.84h124.62M88.23 44.13l5.76-9.97" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
