/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_year_built__from_2002': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<path pid="0" d="M7.96 13.71h123.38s5.88 0 5.88 5.88v111.62s0 5.88-5.88 5.88H7.96s-5.88 0-5.88-5.88V19.59s0-5.88 5.88-5.88zM31.46 1.96v29.37M107.83 1.96v29.37M2.08 43.08H137.2" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M60.17 82.95v13.36c0 3.49 2.89 6.32 6.46 6.32H67c3.57 0 6.46-2.83 6.46-6.32V82.95c0-3.49-2.89-6.32-6.46-6.32h-.37c-3.57 0-6.46 2.83-6.46 6.32zM80.3 82.95v13.36c0 3.49 2.89 6.32 6.46 6.32h.37c3.57 0 6.46-2.83 6.46-6.32V82.95c0-3.49-2.89-6.32-6.46-6.32h-.37c-3.57 0-6.46 2.83-6.46 6.32zM53.1 102.6H40.13v-4.2c0-2.31 1.22-4.44 3.22-5.6l6.53-3.81a6.483 6.483 0 003.22-5.6v-.24c0-3.58-2.9-6.48-6.48-6.48-2.82 0-5.59 1.8-6.48 4.32M113.64 102.6h-12.97v-4.2c0-2.31 1.22-4.44 3.22-5.6l6.53-3.81a6.483 6.483 0 003.22-5.6v-.24c0-3.58-2.9-6.48-6.48-6.48-2.82 0-5.59 1.8-6.48 4.32M19.08 81.34l8.99 9M28.07 90.34l-8.99 8.99" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
