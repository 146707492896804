/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_old__natural_gas': {
    width: 97,
    height: 116,
    viewBox: '0 0 97 116',
    data: '<path pid="0" d="M94.89 68c0 25.7-20.8 46.5-46.5 46.5S1.89 93.7 1.89 68c0-13.8 6.1-26.8 16.6-35.7 1.8 6.2 5 12 9.2 16.9 3.9-7 6-14.9 5.9-22.9 0-8.6-2.2-17-6.4-24.5 34.7 5.5 52.8 40.4 50.5 61.2 5.6-1.8 10.8-4.7 15.2-8.5 1.3 4.3 2 8.8 2 13.4v.1z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M51.49 60.6c.5-.2 1.1 0 1.5.4.3.5.3 1.1 0 1.5-4.6 6.3-5.3 14.8-1.6 21.7 2.2-1.5 4-3.6 4.9-6.2.1-.4.4-.6.7-.8.3-.2.7-.2 1 0 6.5 3.3 10.4 10.3 9.8 17.6.2 10.6-8.2 19.4-18.8 19.6h-.8c-10.8 0-19.6-8.8-19.6-19.6v-.5c.2-14.8 9.2-28.1 22.8-33.7h.1z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
