/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people_count__3_4': {
    width: 119,
    height: 99,
    viewBox: '0 0 119 99',
    data: '<path pid="0" d="M67.41 97.28l2.08-24.95h10.4V57.77c0-11.48-9.31-20.79-20.79-20.79-11.48 0-20.79 9.31-20.79 20.79v14.56h10.4l2.08 24.95h16.63-.01zM86.78 80.17l1.43 17.11h16.63l2.08-24.95h10.4V57.77c0-11.48-9.31-20.79-20.79-20.79-4.5 0-8.88 1.46-12.48 4.16M44.54 16.19c0 8.04 6.52 14.56 14.56 14.56 8.04 0 14.56-6.52 14.56-14.56 0-8.04-6.52-14.56-14.56-14.56-8.04 0-14.56 6.52-14.56 14.56zM81.97 16.19c0 8.04 6.52 14.56 14.56 14.56 8.04 0 14.56-6.52 14.56-14.56 0-8.04-6.52-14.56-14.56-14.56-8.04 0-14.56 6.52-14.56 14.56zM35.54 41.14c-3.6-2.7-7.98-4.16-12.48-4.16-11.48 0-20.79 9.31-20.79 20.79v14.56h10.4l2.08 24.95h16.63l1.43-17.11M37.62 16.19c0-8.04-6.52-14.56-14.56-14.56-8.04 0-14.56 6.52-14.56 14.56 0 8.04 6.52 14.56 14.56 14.56 8.04 0 14.56-6.52 14.56-14.56z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
