/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'floor_new_heating__1': {
    width: 110,
    height: 115,
    viewBox: '0 0 110 115',
    data: '<g clip-path="url(#clip0_3175_579)" _stroke="#000" stroke-width="3" stroke-linecap="round"><path pid="0" d="M89.71 32.7V9.96c0-1.28-1.04-2.32-2.32-2.32h-9.28c-1.28 0-2.32 1.04-2.32 2.32v9.93L56.46 2.11c-.89-.82-2.26-.82-3.15 0L2.25 50.02c-1.32 1.21-.83 3.39.88 3.92.23.07.46.11.7.11h11.6v54.1c0 2.56 2.08 4.64 4.64 4.64H89.7c2.56 0 4.64-2.08 4.64-4.64v-54.1h11.6c1.79 0 2.91-1.93 2.01-3.48-.12-.2-.27-.39-.44-.55L89.69 32.7h.02zM15.43 77.39h78.92M66.89 67.21c0 1.5-1.21 2.71-2.71 2.71" stroke-linejoin="round"/><path pid="1" d="M42.89 53.15v2.89M42.89 60.15v2.89M66.89 53.15v2.89M66.89 60.15v2.89"/><path pid="2" d="M45.6 69.92c-1.5 0-2.71-1.21-2.71-2.71M45.6 46.15c-1.5 0-2.71 1.21-2.71 2.71M64.18 46.15c1.5 0 2.71 1.21 2.71 2.71" stroke-linejoin="round"/><path pid="3" d="M50.52 46.15h2.21M57.38 46.15h2.21M50.52 69.92h2.21M57.38 69.92h2.21"/></g><defs><clipPath id="clip0_3175_579"><path pid="4" _fill="#fff" d="M0 0h109.78v114.29H0z"/></clipPath></defs>'
  }
})
