/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pipeline_system_exists__no': {
    width: 139,
    height: 139,
    viewBox: '0 0 139 139',
    data: '<g clip-path="url(#clip0_3548_14)" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M21.28 21.28l95.55 95.55M1.5 69.06c0 37.31 30.25 67.56 67.56 67.56s67.56-30.25 67.56-67.56S106.37 1.5 69.06 1.5 1.5 31.75 1.5 69.06z"/><path pid="1" d="M36.48 86.8c0-1.74-1.74-1.74-1.74-1.74h-6.96c-1.74 0-1.74 1.74-1.74 1.74v17.4c0 1.74 1.74 1.74 1.74 1.74h6.96c1.74 0 1.74-1.74 1.74-1.74V86.8zM100.81 52c0 1.74 1.74 1.74 1.74 1.74h6.96c1.74 0 1.74-1.74 1.74-1.74V34.6c0-1.74-1.74-1.74-1.74-1.74h-6.96c-1.74 0-1.74 1.74-1.74 1.74V52zM55.05 64.26c-1.16.33-1.17 1.66-1.17 1.66v6.96c0 1.74 1.74 1.74 1.74 1.74h9.79M74.87 74.62h3.33c1.74 0 1.74-1.74 1.74-1.74v-6.96c0-1.74-1.74-1.74-1.74-1.74H64.36M57.36 74.62v6.96c0 3.84-3.12 6.96-6.96 6.96H36.48v13.92H50.4c11.53-.01 20.87-9.35 20.88-20.88M76.45 64.18v-6.96c0-3.84 3.12-6.96 6.96-6.96h17.4V36.34h-17.4c-11.53.01-20.87 9.35-20.88 20.88v5.14l1.82 1.82h12.1z"/></g><defs><clipPath id="clip0_3548_14"><path pid="2" _fill="#fff" d="M0 0h138.12v138.12H0z"/></clipPath></defs>'
  }
})
