/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people_count__9_or_more': {
    width: 133,
    height: 129,
    viewBox: '0 0 133 129',
    data: '<path pid="0" d="M1.92 57.19h129.25v47.12H1.92V57.19zM67.39 27.49c6.95 0 12.59-5.64 12.59-12.59S74.34 2.31 67.39 2.31 54.8 7.95 54.8 14.9s5.64 12.59 12.59 12.59zM45.93 45.92c1.06-1.95 2.41-3.75 4.01-5.35a23.485 23.485 0 0116.62-6.88 23.526 23.526 0 0120.63 12.23M108.51 27.49c6.95 0 12.59-5.64 12.59-12.59s-5.64-12.59-12.59-12.59S95.92 7.95 95.92 14.9s5.64 12.59 12.59 12.59z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M131.17 57.19c0-6.23-2.48-12.21-6.88-16.62a23.484 23.484 0 00-16.62-6.88c-6.24 0-12.21 2.48-16.62 6.88a23.484 23.484 0 00-6.88 16.62M26.26 27.49c6.95 0 12.59-5.64 12.59-12.59S33.21 2.31 26.26 2.31 13.67 7.95 13.67 14.9s5.64 12.59 12.59 12.59zM48.92 57.19c0-6.23-2.48-12.21-6.88-16.62a23.484 23.484 0 00-16.62-6.88c-6.24 0-12.21 2.48-16.62 6.88a23.484 23.484 0 00-6.88 16.62M55.87 111.34l2.25 16.01h17.14l1.97-16.01M93.24 111.34l2.25 16.01h17.14l1.97-16.01M18.51 111.34l2.25 16.01H37.9l1.97-16.01M63.02 74.11v1.09c0 3.61-2.93 6.54-6.54 6.54s-6.54-2.93-6.54-6.54v-1.09c0-3.61 2.93-6.54 6.54-6.54s6.54 2.93 6.54 6.54zm0 0v13.08c0 3.61-2.93 6.54-6.54 6.54-2.85 0-5.64-1.82-6.54-4.36M72.12 79.32h12.72M78.48 85.68V72.97" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
