/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_new__oil': {
    width: 76,
    height: 112,
    viewBox: '0 0 76 112',
    data: '<path pid="0" d="M2.21 73.7c0 20.1 15.8 35.9 35.9 35.9 20.1 0 35.9-15.8 35.9-35.9 0-20.1-35.9-71.9-35.9-71.9S2.21 53.5 2.21 73.7zM61.01 81s.9 10.7-10 13.2" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
