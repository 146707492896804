/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people_count__5_6': {
    width: 133,
    height: 129,
    viewBox: '0 0 133 129',
    data: '<path pid="0" d="M1.78 57.19h129.25v47.12H1.78V57.19zM67.24 27.49c6.95 0 12.59-5.64 12.59-12.59S74.19 2.31 67.24 2.31 54.65 7.95 54.65 14.9s5.64 12.59 12.59 12.59zM45.78 45.92c1.06-1.95 2.41-3.75 4.01-5.35a23.484 23.484 0 0116.62-6.88 23.526 23.526 0 0120.63 12.23M108.37 27.49c6.95 0 12.59-5.64 12.59-12.59s-5.64-12.59-12.59-12.59S95.78 7.95 95.78 14.9s5.64 12.59 12.59 12.59z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M131.03 57.19c0-6.23-2.48-12.21-6.88-16.62a23.484 23.484 0 00-16.62-6.88c-6.24 0-12.21 2.48-16.62 6.88a23.484 23.484 0 00-6.88 16.62M26.12 27.49c6.95 0 12.59-5.64 12.59-12.59S33.07 2.31 26.12 2.31 13.53 7.95 13.53 14.9s5.64 12.59 12.59 12.59zM48.78 57.19c0-6.23-2.48-12.21-6.88-16.62a23.484 23.484 0 00-16.62-6.88c-6.24 0-12.21 2.48-16.62 6.88a23.484 23.484 0 00-6.88 16.62M55.73 111.34l2.25 16.01h17.14l1.97-16.01M93.09 111.34l2.25 16.01h17.14l1.97-16.01M18.36 111.34l2.25 16.01h17.14l1.97-16.01M51.79 67.57H40.81l-1.1 12.08 2.54-1.27c1.22-.61 2.57-.93 3.93-.93h.12a6.59 6.59 0 016.59 6.59v3.29a6.59 6.59 0 01-6.59 6.59c-2.87 0-5.68-1.83-6.59-4.39M82.7 87.19V86.1c0-3.61 2.93-6.54 6.54-6.54s6.54 2.93 6.54 6.54v1.09c0 3.61-2.93 6.54-6.54 6.54s-6.54-2.93-6.54-6.54zm0 0V74.11c0-3.61 2.93-6.54 6.54-6.54 2.85 0 5.64 1.82 6.54 4.36M60.88 79.32H73.6" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
