/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heat_distribution__underfloor_heating': {
    width: 131,
    height: 124,
    viewBox: '0 0 131 124',
    data: '<path pid="0" d="M106.47 38.9V11.85c0-1.52-1.24-2.76-2.76-2.76H92.67c-1.52 0-2.76 1.24-2.76 2.76v11.81L66.93 2.52a2.746 2.746 0 00-3.74 0L2.46 59.5c-1.56 1.44-.98 4.03 1.04 4.67.27.08.55.13.83.13h13.8v52.45c0 3.05 2.47 5.52 5.52 5.52h82.81c3.05 0 5.52-2.47 5.52-5.52V64.3h13.8c2.12 0 3.46-2.3 2.39-4.14-.14-.24-.32-.46-.52-.65l-21.19-20.6.01-.01zM24.23 112.72h81.6" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M64.87 85.66a7.917 7.917 0 00-1.74 4.53c0 2.83 1.74 5.67 1.74 8.49a7.89 7.89 0 01-1.74 4.53M56.77 85.66a7.917 7.917 0 00-1.74 4.53c0 2.83 1.74 5.67 1.74 8.49a7.89 7.89 0 01-1.74 4.53M72.97 85.66a7.999 7.999 0 00-1.75 4.53c0 2.83 1.75 5.67 1.75 8.49-.09 1.66-.7 3.24-1.75 4.53" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
