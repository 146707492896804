/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_new__pellets': {
    width: 121,
    height: 87,
    viewBox: '0 0 121 87',
    data: '<path pid="0" d="M37.13 26.94l-18.8 7.9c-4 1.7-6.1 6.7-4.4 10.7 1.7 4 6.7 6.1 10.7 4.4l18.8-7.9c4-1.7 6.1-6.7 4.4-10.7-2-4.2-6.7-6.1-10.7-4.4zM31.53 67.14l-17.3-10.7c-3.7-2.3-8.9-1.1-11.3 2.6-2.3 3.7-1.1 8.9 2.6 11.3l17.3 10.7c3.7 2.3 8.9 1.1 11.3-2.6 2.2-4 1.1-8.9-2.6-11.3zM52.53 5.44l-20-3.7c-4.3-.8-8.7 2.2-9.5 6.5-.8 4.3 2.2 8.7 6.5 9.5l20 3.7c4.3.8 8.7-2.2 9.5-6.5.6-4.5-2.2-8.7-6.5-9.5zM60.43 71.84l-6.5-19.3c-1.4-4.2-6.2-6.6-10.4-5.2-4.2 1.4-6.6 6.2-5.2 10.4l6.5 19.3c1.4 4.2 6.2 6.6 10.4 5.2 4.3-1.6 6.6-6.2 5.2-10.4zM88.03 39.54l-20.2 2.2c-4.4.5-7.7 4.7-7.2 9 .5 4.4 4.7 7.7 9 7.2l20.2-2.2c4.4-.5 7.7-4.7 7.2-9-.7-4.5-4.7-7.7-9-7.2zM75.93 7.74l-15.1 13.6c-3.3 2.9-3.5 8.3-.6 11.6 2.9 3.3 8.3 3.5 11.6.6l15.1-13.6c3.3-2.9 3.5-8.3.6-11.6-3.2-3.3-8.3-3.5-11.6-.6zM82.63 84.74l18.8-7.9c4-1.7 6.1-6.7 4.4-10.7-1.7-4-6.7-6.1-10.7-4.4l-18.8 7.9c-4 1.7-6.1 6.7-4.4 10.7 2 4.2 6.7 6.1 10.7 4.4zM117.73 38.84l-8.9-18.3c-1.9-4-7-5.7-10.9-3.8-4 1.9-5.7 7-3.8 10.9l8.9 18.3c1.9 4 7 5.7 10.9 3.8 4-2.2 5.7-7 3.8-10.9z" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
