/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__end_terraced_house': {
    width: 147,
    height: 59,
    viewBox: '0 0 147 59',
    data: '<path pid="0" d="M49.27 18.8V6.5c0-.69-.56-1.26-1.26-1.26h-5.02c-.69 0-1.26.56-1.26 1.26v5.37L31.28 2.26a1.26 1.26 0 00-1.7 0L1.97 28.17a1.255 1.255 0 00.85 2.18H9.1V54.2c0 1.39 1.12 2.51 2.51 2.51h37.66c1.39 0 2.51-1.12 2.51-2.51V30.35h6.28a1.254 1.254 0 00.85-2.18l-9.64-9.37zM134.82 18.8V6.5c0-.69-.56-1.26-1.26-1.26h-5.02c-.69 0-1.26.56-1.26 1.26v5.37l-10.45-9.61a1.26 1.26 0 00-1.7 0L87.52 28.17a1.255 1.255 0 00.85 2.18h6.28V54.2c0 1.39 1.12 2.51 2.51 2.51h37.66c1.39 0 2.51-1.12 2.51-2.51V30.35h6.28a1.254 1.254 0 00.85-2.18l-9.64-9.37zM59.31 56.7h9.04M76.43 56.7h9.04M87.65 14.7l6.39 6.38M73.24 1.92l6.39 6.39M52.93 21.08l6.38-6.38M66.85 8.31l6.39-6.39" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
