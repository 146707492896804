/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__201_300': {
    width: 133,
    height: 133,
    viewBox: '0 0 133 133',
    data: '<path pid="0" d="M2.06 25.07v-12s0-11.25 12.12-11.25h105.08s12.12 0 12.12 11.25M131.39 13.06V119.9s0 11.25-12.12 11.25h-14.22M96.84 44.6v6" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M96.84 62.77v60.13s0 8.24-8.89 8.24H10.93s-8.89 0-8.89-8.24V44.6s0-8.24 8.89-8.24h77.02s.6 0 1.48.14" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="12.17 12.17"/><path pid="2" d="M94.84 39.1c1.16 1.22 2 2.97 2 5.51" _stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
